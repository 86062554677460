import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import mastercard from '../../public/visit-visa-insurance/images/footer/mastercard.svg';
import visa from '../../public/visit-visa-insurance/images/footer/visa.svg';
import shoryWallet from '../../public/visit-visa-insurance/images/footer/wallet.svg';
import footerLogo from '../../public/visit-visa-insurance/images/mof_logo.svg';
import { useLocale } from '@ninja/nextjs';

const SiteFooter = () => {
	const { t } = useTranslation();
	const { isRtlLocale } = useLocale();

	const arabicRedirect = '/ar/visit-visa-insurance/';

	return (
		<div className="flex flex-col gap-10 sm:gap-28 md:gap-60 justify-between text-caption1 text-gray-600 sm:flex-row py-6">
			<div className="flex flex-col gap-4 sm:h-full sm:flex-col">
				<div className="flex gap-2.5 text-brand-600 sm:gap-2.5 leading-none">
					<a
						className="cursor-pointer"
						target="_blank"
						rel="noopener"
						href={
							isRtlLocale
								? `${arabicRedirect}terms-and-conditions`
								: 'terms-and-conditions'
						}
					>
						{t('shared.footer.left-section.terms')}
					</a>
					<a
						className="cursor-pointer"
						target="_blank"
						rel="noopener"
						href={
							isRtlLocale ? `${arabicRedirect}privacy-policy` : 'privacy-policy'
						}
					>
						{t('shared.footer.left-section.privacy')}
					</a>
				</div>
				<p className="text-gray-600">
					{t('shared.footer.left-section.long-text')}
				</p>
				<div>
					<p className="text-gray-600">{t('shared.footer.payment-options')}</p>
					<div className="flex gap-2 align-top mt-2">
						<Image src={visa} alt="visa" className="h-6" width={0} height={0} />
						<Image
							src={mastercard}
							alt="mastercard"
							className="h-6"
							width={0}
							height={0}
						/>
						<Image
							src={shoryWallet}
							alt="shoryWallet"
							className="h-6"
							width={0}
							height={0}
						/>
					</div>
				</div>
			</div>
			<div className="flex sm:self-center min-w-fit h-full items-center justify-start gap-0.5 sm:justify-between sm:gap-0.5">
				<Image
					width={0}
					height={0}
					src={footerLogo}
					alt="footer logo"
					className="h-12 w-11"
				/>
				<p className="text-caption2 leading-tight w-24">
					<Trans i18nKey="multiline">{t('shared.footer.right-section')}</Trans>
				</p>
			</div>
		</div>
	);
};

export default SiteFooter;
