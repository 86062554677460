import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { Button, FullPageLayout, SimpleStepper } from '@ninja/web';
import { STEPPER_STEPS } from '@visit-visa/lib-customer';
import CompanyDetailsHeader from '../../components/CompanyDetailsHeader';
import SiteFooter from '../../components/SiteFooter';
import useRedirectOnLogout from '../../hooks/useRedirectOnLogout';

interface IButton {
	text?: string;
	onClick: () => void;
	disabled?: boolean;
	loading?: boolean;
}

interface IPageLayoutProps {
	children: ReactNode;
	containerWidth?: 'stretched' | 'container';
	currentStep?: STEPPER_STEPS;
	title?: string;
	footer?: ReactNode;
	backButton?: IButton;
	continueButton?: IButton;

	HeaderComponent?: ReactNode;
}

const PageLayout = (props: IPageLayoutProps) => {
	const {
		children,
		containerWidth = 'container',
		title,
		footer = <SiteFooter />,
		currentStep,
		continueButton,
		backButton,
		HeaderComponent,
	} = props;

	const { t } = useTranslation();

	useRedirectOnLogout();

	return (
		<FullPageLayout
			footer={footer}
			footerClassName="h-fit px-4 lg:px-16 md:px-6"
			contentContainerWidth={'stretched'}
			subHeader={
				<CompanyDetailsHeader
					title={title ? t(title) ?? '' : ''}
					onClickBackButton={backButton?.onClick}
				/>
			}
		>
			{HeaderComponent}
			<section
				className={`${
					containerWidth === 'container' ? 'lg:w-200 mx-auto' : ''
				} flex flex-col h-full lg:h-auto`}
			>
				{currentStep && (
					<div className="mb-8">
						<SimpleStepper
							items={[
								t('quota-purchase.stepper.quota-details'),
								t('quota-purchase.stepper.summary'),
								t('quota-purchase.stepper.payment'),
							]}
							current={currentStep}
						/>
					</div>
				)}

				{children}
				{!backButton?.onClick && !continueButton?.onClick ? null : (
					<section
						className={`h-full flex flex-col justify-start md:justify-end w-full lg:static sticky bottom-0 bg-white lg:pb-0 pb-3 ${
							!continueButton?.onClick ? 'hidden md:flex' : ''
						}`}
					>
						<div className="border-t border-gray-200 mb-6 mt-8" />
						<div className="flex w-full items-center justify-between">
							<Button
								onClick={backButton?.onClick}
								isLoading={backButton?.loading}
								disabled={backButton?.disabled}
								variant="secondary"
								layoutClassName={
									backButton
										? ['hidden', 'md:visible', 'md:w-auto', 'md:block']
										: ['hidden', 'md:invisible', 'md:block']
								}
							>
								{backButton?.text || t('quota-purchase.common.buttons.back')}
							</Button>
							<div className="md:w-auto w-full">
								<Button
									onClick={continueButton?.onClick}
									isLoading={continueButton?.loading}
									disabled={continueButton?.disabled}
									layoutClassName={
										continueButton ? ['md:w-auto', 'w-full'] : 'invisible'
									}
								>
									{continueButton?.text ||
										t('quota-purchase.common.buttons.continue') ||
										''}
								</Button>
							</div>
						</div>
					</section>
				)}
			</section>
		</FullPageLayout>
	);
};

export default PageLayout;
