import { useCallback, useEffect } from 'react';
import {
	STEPPER_STEPS,
	useQuotaPurchase,
	useStepperBasedOnData,
} from '@visit-visa/lib-customer';
import { IRoutesParamsTypes } from '../config/routes-and-permissions';
import { useWebNavigation } from '../contexts/navigation';

const pageSequence: {
	[key in STEPPER_STEPS]: keyof IRoutesParamsTypes;
} = {
	[STEPPER_STEPS.INDEX]: 'QuotaPurchaseLanding',
	[STEPPER_STEPS.QUOTA]: 'QuotaDetails',
	[STEPPER_STEPS.SUMMARY]: 'QuotaSummary',
	[STEPPER_STEPS.PAYMENT]: 'PaymentOptions',
	[STEPPER_STEPS.SUCCESSFUL]: 'QuotaIssuance',
};

const getPageStep = (page: undefined | keyof IRoutesParamsTypes) => {
	const pageStep = Object.entries(pageSequence).find((p) => p[1] === page)?.[0];

	if (pageStep) return parseInt(pageStep) as STEPPER_STEPS;
};

function useRedirectToCorrectStep() {
	const { getCurrentStep, setStep } = useStepperBasedOnData();
	const {
		navigationPush,
		navigationReplace,
		getCurrentPage,
		navigationGoBack,
	} = useWebNavigation();
	const { companyDetailsData, isAgencyNonEditable } = useQuotaPurchase();

	const currentStep = getCurrentStep();
	const currentPage = getCurrentPage();
	const currentPageStep = getPageStep(currentPage);

	useEffect(() => {
		if (currentPageStep === undefined) {
			navigationReplace(pageSequence[STEPPER_STEPS.INDEX]);

			return;
		}

		const pageSequenceRoute = pageSequence[currentStep];

		if (currentPageStep === currentStep) return;

		if (!companyDetailsData && isAgencyNonEditable) {
			navigationPush('Landing');

			return;
		}

		if (currentStep < currentPageStep) navigationReplace(pageSequenceRoute);

		if (
			currentPageStep < currentStep &&
			currentStep === STEPPER_STEPS.SUCCESSFUL
		)
			navigationPush('QuotaIssuance');
	}, [
		companyDetailsData,
		getCurrentStep,
		navigationPush,
		navigationReplace,
		getCurrentPage,
		navigationGoBack,
		currentPageStep,
		currentStep,
		isAgencyNonEditable,
	]);

	const onNextStep = useCallback(() => {
		if (currentPageStep === undefined) return;

		const step = 1;
		const nextPageStep = (currentPageStep + step) as STEPPER_STEPS;
		const pageSequenceRoute = pageSequence[nextPageStep];

		if (nextPageStep > currentStep) setStep(nextPageStep);

		navigationPush(pageSequenceRoute);
	}, [currentPageStep, currentStep, setStep, navigationPush]);

	return { nextStep: onNextStep, previousStep: navigationGoBack };
}

export default useRedirectToCorrectStep;
