import { useTranslation } from 'next-i18next';
import { serverSideTranslationsKeys } from '@ninja/nextjs/server';
import { useQuotaPurchase } from '@visit-visa/lib-customer';
import PageError from '../../components/PageError';
import useRedirectToCorrectStep from '../../hooks/useRedirectToCorrectStep';
import { PageLayout } from '../../layout';
import LandingScreen from '../../components/LandingScreen';

export const getStaticProps = async ({ locale }: { locale: 'ar' | 'en' }) => {
	return {
		props: {
			...(await serverSideTranslationsKeys(locale || 'en', [{ ns: 'common' }])),
		},
	};
};

const QuotaPurchaseLanding = () => {
	const { companyDetailsData, isAgencyNonEditable } = useQuotaPurchase();

	useRedirectToCorrectStep();
	const { t } = useTranslation();

	if (!companyDetailsData && isAgencyNonEditable) return <PageError />;

	return (
		<PageLayout
			title={t('landing.page-title') ?? ''}
			containerWidth="stretched"
		>
			<div className="flex items-center justify-center -my-3 pt-9">
				<LandingScreen />
			</div>
		</PageLayout>
	);
};

export default QuotaPurchaseLanding;
