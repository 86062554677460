import { useEffect } from 'react';
import { useQuotaPurchase, useUserInfo } from '@visit-visa/lib-customer';
import { useWebNavigation } from '../contexts/navigation';

const useRedirectOnLogout = () => {
	const { isICP, isClearable, onSetIsClearable } = useQuotaPurchase();
	const { userLoggedIn, userCompany } = useUserInfo();
	const { navigationReplace } = useWebNavigation();

	useEffect(() => {
		if (userLoggedIn) onSetIsClearable();

		if (!isICP && !userLoggedIn && userCompany && isClearable) {
			navigationReplace('NonICP');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userCompany, isICP, userLoggedIn, isClearable]);
};

export default useRedirectOnLogout;
