import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { ArrowDownIcon, ThinArrowRightIcon } from '@ninja/icons/web';
import { useLocale } from '@ninja/nextjs';
import {
	Collapsible,
	CollapsibleButton,
	CollapsiblePanel,
	TextEllipsisTooltip,
} from '@ninja/web';
import { useQuotaPurchase } from '@visit-visa/lib-customer';

type CompanyDetailsDataProps = {
	companyDetailsData?: {
		cityName: string;
		establishmentNumber: string;
		companyName: string;
	};
	className?: string;
};
const CompanyData = ({
	companyDetailsData,
	className,
}: CompanyDetailsDataProps) => {
	const { t } = useTranslation();

	return (
		<div className={`flex md:flex-row flex-col ${className}`}>
			<HeaderItem
				label={t('company-details-header.agencyName') ?? ''}
				body={companyDetailsData?.companyName}
				className="hidden md:flex"
			/>
			<HeaderItem
				label={t('company-details-header.establishment-card-number') ?? ''}
				body={companyDetailsData?.establishmentNumber}
			/>
			<HeaderItem
				label={t('company-details-header.city') ?? ''}
				body={companyDetailsData?.cityName}
			/>
		</div>
	);
};

const CompanyDetailsHeader = ({
	title = '',
	onClickBackButton,
}: {
	title?: string;
	onClickBackButton?: () => void;
}) => {
	const { companyDetailsData } = useQuotaPurchase();
	const { isRtlLocale } = useLocale();
	const [open, setIsOpen] = useState(false);

	return (
		<>
			<div className="flex w-full flex-wrap border-b border-b-gray-200 px-6 lg:px-16 py-4 items-center bg-gray-50 justify-between gap-x-4 gap-y-4 ">
				{title && (
					<h1 className="text-h1 font-bold hidden md:block">{title}</h1>
				)}
				{companyDetailsData && (
					<>
						<div className="block md:hidden w-full">
							<Collapsible open={open} onToggle={setIsOpen}>
								<CollapsibleButton>
									<div className="flex justify-between items-center w-full">
										<div className="font-medium text-body">
											{companyDetailsData?.companyName ?? ''}
										</div>
										<ArrowDownIcon
											className={`w-3 h-3 ${open ? 'rotate-180' : ''}`}
										/>
									</div>
								</CollapsibleButton>
								<CollapsiblePanel>
									<div className="pt-5">
										<CompanyData companyDetailsData={companyDetailsData} />
									</div>
								</CollapsiblePanel>
							</Collapsible>
						</div>
						<CompanyData
							companyDetailsData={companyDetailsData}
							className="hidden md:flex gap-20"
						/>
					</>
				)}
			</div>
			{title && (
				<div className="flex md:hidden mt-8 items-center px-4">
					{onClickBackButton && (
						<ThinArrowRightIcon
							className={isRtlLocale ? '' : 'rotate-180'}
							onClick={onClickBackButton}
						/>
					)}
					<h1 className="text-h1 font-bold flex-1 text-center">{title}</h1>
				</div>
			)}
		</>
	);
};

const HeaderItem = ({ label = '', body = '', className = '' }) => {
	return (
		<div className={`flex flex-1 flex-col gap-0.5 ${className}`}>
			<p className="text-caption1 font-normal text-gray-700 whitespace-nowrap">
				{label}
			</p>
			<div className="text-paragraph font-medium text-black">
				<TextEllipsisTooltip content={body} maxWidth="200px" />
			</div>
		</div>
	);
};

export default CompanyDetailsHeader;
