import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useEffect } from 'react';
import { useLocale } from '@ninja/nextjs';
import { Button, Card, LinkButton, TextLink } from '@ninja/web';
import { useQuotaPurchase, useUserInfo } from '@visit-visa/lib-customer';
import UAECity from '../../public/visit-visa-insurance/images/UAE-city.svg';
import UAELogo from '../../public/visit-visa-insurance/images/UAE-logo.svg';
import ExploreOptions from '../../public/visit-visa-insurance/images/explore-options.svg';
import RegisterPay from '../../public/visit-visa-insurance/images/register-pay.svg';
import SpecifyRequirement from '../../public/visit-visa-insurance/images/specify-requirement.svg';
import { useWebNavigation } from '../contexts/navigation';

const LandingScreen = () => {
	const { navigationPush, navigationReplace } = useWebNavigation();
	const { t } = useTranslation();
	const { isRtlLocale } = useLocale();
	const { userLoggedIn, isCompanyExist } = useUserInfo();
	const { isICP } = useQuotaPurchase();

	useEffect(() => {
		if (userLoggedIn && !isCompanyExist && !isICP) {
			navigationReplace('NonICP');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLoggedIn, isCompanyExist, isICP]);

	const journeySteps = [
		{
			title: `${t('landing.specify-requirements')}`,
			description: `${t('landing.requirement-desc')}`,
			imageSrc: SpecifyRequirement,
			altImageText: 'specify-your-requirements',
			stepCount: 1,
		},
		{
			title: `${t('landing.explore-options')}`,
			description: `${t('landing.explore-options-desc')}`,
			imageSrc: ExploreOptions,
			altImageText: 'explore-options',
			stepCount: 2,
		},
		{
			title: `${t('landing.register-pay')}`,
			description: `${t('landing.register-pay-desc')}`,
			imageSrc: RegisterPay,
			altImageText: 'register-and-pay',
			stepCount: 3,
		},
	];

	const renderJourneySteps = (
		imageSrc: string,
		altImageText: string,
		title: string,
		description: string,
		stepCount: number,
	) => {
		return (
			<Card
				className="flex flex-col items-center flex-shrink-0 text-center lg:w-auto w-72 lg:flex-1 text-caption1"
				key={stepCount}
			>
				<div
					className={`w-6 h-6 bg-black text-white p-1 relative rounded-full text-center xl:-top-11 -top-9 ${
						isRtlLocale ? '' : '-'
					}left-[60%] font-bold`}
				>
					{stepCount}
				</div>
				<Image className="w-8 h-12" src={imageSrc} alt={altImageText} />
				<p className="pt-5 pb-2 font-bold text-body">{title}</p>
				<p>{description}</p>
			</Card>
		);
	};

	return (
		<div className="flex flex-col items-center justify-center max-w-5xl overflow-hidden">
			<div className="flex flex-col-reverse items-center md:flex-row md:items-start gap-y-3">
				<div className="flex-1">
					<p className="font-bold text-h1">{t('landing.buy-group-policy')}</p>
					<div className="flex flex-col gap-2 text-paragraph mt-2 w-[90%]">
						<p>{t('landing.travel-agency-need')}</p>
						<p>{t('landing.shory-platform')}</p>
					</div>
				</div>
				<Image
					src={UAELogo}
					alt="United Arab Emirates Logo"
					height={254}
					width={256}
				/>
			</div>
			<div className="w-full pt-8">
				<p className="font-bold text-h1">{t('landing.simple-steps')}</p>
				<p className="my-2 text-paragraph">{t('landing.with-shory')}</p>
				<div className="flex w-full gap-6 px-6 pt-3 pb-3 my-8 overflow-auto lg:overflow-visible xl:pt-0">
					{journeySteps.map((step) =>
						renderJourneySteps(
							step.imageSrc,
							step.altImageText,
							step.title,
							step.description,
							step.stepCount,
						),
					)}
				</div>
			</div>

			<div className="flex flex-col items-center gap-6 text-center text-paragraph">
				<div>{t('landing.after-complete')}</div>
				{(!userLoggedIn || isCompanyExist) && (
					<Button
						onClick={() => {
							navigationPush('QuotaDetails');
						}}
					>
						{t('landing.start-now') ?? ''}
					</Button>
				)}
				<p className="flex flex-row items-center gap-1 font-medium text-gray-600 text-paragraph">
					{!userLoggedIn && t('landing.already-account')}
					<TextLink
						text={
							userLoggedIn ? t('landing.go-to-my-account') : t('landing.login')
						}
						onClick={() => {
							navigationPush('Dashboard');
						}}
					/>
				</p>
				{userLoggedIn && !isCompanyExist && (
					<>
						<Button onClick={() => navigationPush('SSO_Logout')}>
							{t('shared.user-setting-menu.logout-continue') ?? ''}
						</Button>
						<p>{t('landing.already-registered')}</p>
					</>
				)}
				<Image
					src={UAECity}
					alt="United Arab Emirates City"
					width={543}
					height={243}
				/>
			</div>
		</div>
	);
};

export default LandingScreen;
