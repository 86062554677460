interface ITwoSideLayoutProps {
	children: React.ReactNode[];
	className?: string;
}

const TwoSideLayout = (props: ITwoSideLayoutProps) => {
	const { children, className = '' } = props;
	const [startComponent, endComponent] = children;

	return (
		<div
			className={`w-full flex gap-6 md:gap-11 flex-col md:flex-row ${className}`}
		>
			<div className="md:w-1/2">{startComponent}</div>

			<div className="md:w-1/2">{endComponent}</div>
		</div>
	);
};

export default TwoSideLayout;
